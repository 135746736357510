import test1 from "./Media/1.jpg";
import test2 from "./Media/3.jpg";
import test3 from "./Media/6.jpg";

export const TestimonialsData = [
  {
    id: 1,
    Testimonail:
      "Consulted Dr. Ramesh for ankle joint sprain and sutures at ankle joint for injury. Was fully satisfied with the treatment process and the assistance they provided",
    name: "Mahendar naik",
    role: "Web Development Course",
    image: test1,
    stars: 5,
  },
  {
    id: 2,
    Testimonail:
      "Recently I visited Vasavi hospital Dr.Ramesh sir ortho For the treatment of back pain,He gave me best medication and suggested for Physiotherapy..I took physiotherapy Treatment From Dr.Preethi Varma..her treatment is good and she told me Physical exercises to reduce pain. Nice treatment",
    name: "Hari Krishna",
    role: "Web Development Course",
    image: test2,
    stars: 5,
  },
  {
    id: 3,
    Testimonail:
      "I fell down and had pain in knee, I consulted Dr. Ramesh sir, he prescribed medication and physiotherapy for one week. I have gone through it, after one week, I got relief from pain, now I am able to work. Thank you Ramesh sir and Dr. Preeti.",
    name: "siva ganga pamulapati",
    role: "Data Analysis Course",
    image: test3,
    stars: 5,
  },
  {
    id: 4,
    Testimonail:
      "Excellent hospital. I was suffering from testicular pain from two and half months. Shown to many doctors but was no use. After Dr Ramesh treatment, it got solved in a week. He is very professional and listen to us very patiently and gives treatment for exact root cause of the issue. And also doesn't not prescribe many medicines. I definitely recommend this hospital and doctor.",
    name: "Srinivas Kura",
    role: "Web Development Course",
    image: test1,
    stars: 5,
  },
  {
    id: 5,
    Testimonail:
      "It's one of the best hospital in the area, with very good hygiene, I rarely saw in my 50 years such a committed doctors with best treatment, generally I do not get satisfy easily , but Dr.J.Vasantha is so much updated and committed doctor gives⁴ excellent treatment, and Dr.M.Ramesh orthopedic doctor is also excellent doctor with excellent diagnosis. It's a fantastic and dependable hospital.",
    name: "Nutakki Seetharamprasad",
    role: "Web Development Course",
    image: test2,
    stars: 5,
  },
  {
    id: 6,
    Testimonail:
      "Will recommend. First time i consulted the doctor when i had stomach infection. I got very good treatment as i could go to my office after taking medicines. Got well in a day. My parents too consult the doctor for their health problems. Infact recently i suggested the doctor to one of my cousin who stays in guntur for her gastric problem. She suffered 3 months with the problem after consulting the doctor her problem was diagnosed correctly and was given good treament. Now my cousin is free from her gastric problem and is very happy that i suggested this doctor. Will definitely recommend",
    name: "Padma Lokesh",
    role: "Data Analysis Course",
    image: test3,
    stars: 5,
  },
  {
    id: 7,
    Testimonail:
      "Recently I had Low back pain and i visited Dr.Ramesh sir For the treatment,He gave medication and suggested Physiotherapy..I took Treatment From Dr.Preethi Varma..She Explained indetailed about the condition, Do’s And Don’ts..Gave me nice treatment.",
    name: "Thagaram Nani",
    role: "Web Development Course",
    image: test1,
    stars: 5,
  },
  {
    id: 8,
    Testimonail:
      "It's a very good Hospital where the treatment is spontaneous. Both the Doctors .. Dr. Ramesh .M and Dr. Vasatha J are very friendly. The health problems with which we approach them are diagnosed accurately and the treatment is given accordingly which is very effective. The staff also are very considerate and friendly. I really am very happy and satisfied with the hospital ambience",
    name: "Suhasini Chintala",
    role: "Web Development Course",
    image: test2,
    stars: 5,
  },
  {
    id: 9,
    Testimonail: `I would like to share my experience with Dr. Ramesh Sir.
      I had high BP and took the necessary medicine . Unfortunately it did not suit me and I started vomiting uncontrollably around 11'0 clock in the night. When contacted Vasavi Hospitals, Dr. Ramesh Sir immediately asked me t come to the hospital. He personally came over and gave the necessary treatment. But due to the continuous vomiting, my body was dehydrated totally and I was not responding properly Sir immediately called for ambulance and shifted me to Ozone Hospitals. He stayed with us till early hours and spoke to the concerned doctors. He came to the hospital personally every day and enquired about me. I recovered completely after three days.
      The above incident shows the dedication and concern of Dr. Ramesh sir towards his patients.
      I hereby convey my whole hearted thanks to Dr. Ramesh Sir.
      Suhasini Reddy.`,
    name: "Manohar Reddy",
    role: "Data Analysis Course",
    image: test3,
    stars: 5,
  },
  {
    id: 10,
    Testimonail: `1.Treatment was super. Staff very courteous. Ambiance was good.
      2.Doctors are very professional, helpful and knowledgeable and are available around the clock.
      3.Non-medical staff was very helpful,communicative and tries to help in all regards
      4.good pharmacy
      5.I would suggest  Dr.Ramesh for othopaedic problems..He is super efficient,does not give false hopes,conducts the procedures with precision,provides detailed information and alternatives available based on your situation..Most importantly he does not provide unnecessary medication ..
      
      Basically I would not be writing this long review but my previous painful experiences let me write it`,
    name: "RAJESH BOBBALA",
    role: "Web Development Course",
    image: test1,
    stars: 5,
  },
  {
    id: 11,
    Testimonail:
      "Great experience with the Vasavi Hospital. Diabetic doc has great patiencs to listen to the problems and she lets us know in detailed information and gives more confidence to face the issues.Should also appreciate hospital staff (especially Shravan), great hospitality and nursing",
    name: "Abhinav Korupathi",
    role: "Web Development Course",
    image: test2,
    stars: 5,
  },
  {
    id: 12,
    Testimonail:
      "My back pain which Iam suffering was completely cured by Dr. Ramesh.when myself working as Accounts officer/ Aptransco/Nellore I have been traveling from Nellore to Hyderabad and due to this or reasons unknown  severe back pain was experienced.I have consulted Dr.Ramesh has induced confidence and tests like x-ray,MRI,pet scan were taken to know the exact point of my back ache.Sri Ramesh on identifying  the point immediately  cured the problem and now a days Iam happily attending my duties in APTRANSCO for the last 2 years. Thank you Sir.Have faith in Doctors please",
    name: "venkata ramana challa",
    role: "Data Analysis Course",
    image: test3,
    stars: 5,
  },
];

export const TestimonialsData2 = [
  {
    id: 1,
    Testimonail:
      "I will Happy with Vasavi Hospital because of first time i will suffaring with Daibetic Rang is 498 but Medam Give some of Sugustions and Started treatment without Insulin within one month Suger is Normal Stage.i will thank ful to Dr.VASANTHA Medam.",
    name: "Elkapally Surender",
    role: "Web Development Course",
    image: test1,
    stars: 5,
  },
  {
    id: 2,
    Testimonail: `Dr.Ramesh Mamidala, orthopedic ,vasavi Hospital, kothapet.
      Its one of the best Hospital . i met with an acident (3 months before) & have gone to hospital with an Ankle broken to my both legs.They recieved me and have given First aid immediatly. Dr. mamidala have talked to me and given an assurence and started my treatment. it took 3 months to cure...now am fit and able to walk...POINT TO BE NOTED ...DR DIDN'T SUGGEST FOR SURGERY, SIR TREATED ME WITHOUT ANY OPERATION, WITH NOMINAL EXPENCES. Thanks to Dr Ramesh Mamidala....Vasavi Hospital , Kothapet.
      I DONT HESITATE TO RECOMEND PEOPLE TO GO VASAVI HOSPITAL , KOTHAPET ,WHO ARE REALLY NEED OFF..😀`,
    name: "Komanduri Keerthi",
    role: "Web Development Course",
    image: test2,
    stars: 5,
  },
  {
    id: 3,
    Testimonail: `Dr.Vasantha Ma'am provided very sensible and effective covid treatment for us. Me and mother recovered safely with very less problems.
      Would like to thank Ma'am and staff for taking care of us.`,
    name: "krishna mohan",
    role: "Data Analysis Course",
    image: test3,
    stars: 5,
  },
  {
    id: 4,
    Testimonail:
      "I got to visit this hospital  for injury on right hand and approached Dr.Ramesh sir for cure. He explained me very clearly about my  condition and had started treatment. Within couple of days, I have recovered (whereas on consulting other Hospital, they conveyed takes 4 to 5 days of bed rest).  Doctor is very friendly and staff who accompanied with Doctor had frequent visits and  are really helpful and taken care. ..Everyone is very nice and helpful . Thanks to doctors and staff",
    name: "Kavitha Mundrathi",
    role: "Web Development Course",
    image: test1,
    stars: 5,
  },
  {
    id: 5,
    Testimonail:
      "I had a wonderful experience at vasavi hospital and I am extremely satisfied with my visit there. Hospital is clean and the staff are very helpful. Doctor Ramesh is friendly and dedicated. Overall I strongly recommend the place.",
    name: "Gautham Kishore Reddy G",
    role: "Web Development Course",
    image: test2,
    stars: 5,
  },
  {
    id: 6,
    Testimonail:
      "Dr.Ramesh is very good in diagnosing. His attitude is very good. He perform his service in a dedicated manner. Hospital ambience is good and the staff of the hospital is very responsible. Most importantly the charges are reasonable.",
    name: "MAHESH PRABHU",
    role: "Data Analysis Course",
    image: test3,
    stars: 5,
  },
  {
    id: 7,
    Testimonail:
      "Dr.Ramesh sir is very Experienced Doctor, He clearly Explains About the Problem and Gives Proper Treatment..Overall experience is Good..",
    name: "Banoth yakub Yakub",
    role: "Web Development Course",
    image: test1,
    stars: 5,
  },
  {
    id: 8,
    Testimonail:
      "We have been here to orthopedics and general physician . The doctors have been very competent, friendly, interacting nicely with the patient and also willing to talk on the phone if needed. The support staff is also very helpful and always available to help. The billing even takes lesser time . Overall a very good hospital and most importantly 24/7 services",
    name: "rajesh kandakatla",
    role: "Web Development Course",
    image: test2,
    stars: 5,
  },
  {
    id: 9,
    Testimonail:
      "I met with an accident 3 months ago and was diagnosed with 2 compression fractures of my dorsal vertebrae.....I consulted Dr.Ramesh garu .....and it's the 3rd month of my treatment....and I am almost fit to my 100%....thanks to his treatment....a very friendly doctor ....always explains the problem clearly and will give some precautions too...... I am really happy with my treatment 😃😃😃..... thank you Dr.ramesh garu 😇",
    name: "Venkata Sai Rohit Vuppalapati",
    role: "Data Analysis Course",
    image: test3,
    stars: 5,
  },
  {
    id: 10,
    Testimonail: `I highly recommend Vasavi Hospitals- Kothapet, appreciate the doctor for his excellence in Orthopedic treatment. Far better than the hospitals located in this area. Treatment is offered with utmost care and concern.

      Special thanks to Dr. Ramesh.M, More than a good doctor, I value the fact that you were a good listener.
      
      Thank you, Sir.`,
    name: "pravallika budhiraju",
    role: "Web Development Course",
    image: test1,
    stars: 5,
  },
  {
    id: 11,
    Testimonail:
      "Highly recommended for joints pain. Polite staff and a highly experienced doctor. My mother was suffering from joints pain for 2 months when we consulted them with much less medication she recovered. Thank you sir.",
    name: "Hemachalam P",
    role: "Web Development Course",
    image: test2,
    stars: 5,
  },
  {
    id: 12,
    Testimonail:
      "Visited the hospital with ankle pain. I am very much satisfied with Dr Ramesh treatment. He is very professional and patiently explained the situation. Hospital ambience is good and the staff were also very responsible. I am happy with the treatment.",
    name: "jyothi angel",
    role: "Data Analysis Course",
    image: test3,
    stars: 5,
  },
];

export const TestimonialsData3 = [
  {
    id: 1,
    Testimonail:
      "I appreciate the patience levels of the doctor he listens to the patient to clearly and motivates them .He suggests necessary treatment and also gives precautions to prevent the illness.He is one of the best doctor I have ever seen.thanks to Dr.Ramesh sir garu",
    name: "Salka G",
    role: "Web Development Course",
    image: test1,
    stars: 5,
  },
  {
    id: 2,
    Testimonail:
      "“The doctors and staff  are amazing. They are not only convenient, but they are also extremely knowledgeable and kind. The doctors have experience in so many areas of medicine.“Service was quick and efficient! Very helpful.”",
    name: "Madhavi Kakarla",
    role: "Web Development Course",
    image: test2,
    stars: 5,
  },
  {
    id: 3,
    Testimonail:
      "Ramesh sir will be seeing with patience and listens to concerns clearly and give good advices and reviews and great ambience with staff",
    name: "vamsi krishna Maddi",
    role: "Data Analysis Course",
    image: test3,
    stars: 5,
  },
  {
    id: 4,
    Testimonail: `Really it is an osm experience that I had in Vasavi hospital. I am very thankful to Dr.ramesh sir because my father was not able to walk for a far distance because he need to be operated for his knees so  I reached Vasavi hospital with my father he suggested some of physiotherapy and some medications as well
      But now seriously I should be tankful to sir Dr.ramesh now my father is very happy that he is able to walk without any knee joint pains.`,
    name: "ganesh sangem",
    role: "Web Development Course",
    image: test1,
    stars: 5,
  },
  {
    id: 5,
    Testimonail:
      "Very good treatment and doctor is taking so much care.I am suffering with diabetic since 13 years.I am taking treatment since 01.02.2021 in the above hospital .Now my sugar levels are under control after a long time.Lot of thanks to Dr.Vasantha.Jella madam",
    name: "srinivas parasu",
    role: "Web Development Course",
    image: test2,
    stars: 5,
  },
  {
    id: 6,
    Testimonail:
      "I like interaction with DR.Preethi Varma(physotheropy). I got great results with her treatment technics and exercise",
    name: "Sandhya Anthoti",
    role: "Data Analysis Course",
    image: test3,
    stars: 5,
  },
  {
    id: 7,
    Testimonail:
      "Good Hospital with firstclass Facilities and Treatment, Highly recommend to everyone, The doctors are able to find the problem quickly and gives treatment in right manner to make sure u are cured perfectly, I have met 2 different doctors(orthopaedic and general physician) and the response/treatment I got is really great, I think this is one of the best hospital at dilshuknagar locality, You don't feel like you are in a hospital, You feel like you are in your own home or you are with your siblings, Doctors cares you like a mother/father. I highly recommend every one to visit this hospital for othopaedic problems",
    name: "Srikanth",
    role: "Web Development Course",
    image: test1,
    stars: 5,
  },
  {
    id: 8,
    Testimonail: `It is pleasure for me to write this review
      The Hispital is centrally located & easily accessible
      I went on three occasions to this Hospital from the reception desk to Docter all are most co operitive & treated with at most care & I felt as if I am at Home
      All are most cheerful & good in there attitude`,
    name: "Ramprasad Srikanta melanathur",
    role: "Web Development Course",
    image: test2,
    stars: 5,
  },
  {
    id: 9,
    Testimonail:
      "Good orthopedic surgeon very practical approach good and appropriate treatment. Good and satisfactory service by his staff and hospital. Well behavior.",
    name: "Satya Bhaskar Tanuku",
    role: "Data Analysis Course",
    image: test3,
    stars: 5,
  },
  {
    id: 10,
    Testimonail: `a year ago
      Caring of hospitality is very good and caring of patients and friendly behaviour.such a good atmosphere .in time response ..`,
    name: "Murali Krishna Baakam",
    role: "Web Development Course",
    image: test1,
    stars: 5,
  },
  {
    id: 11,
    Testimonail:
      "Stop going to corporate hospitals ..because they are busy in writing all the tests in the world with little knowledge full doctors.I thank Dr. Vasantha mam for no nonsense kind of treatment and may God bless her for the kind of knowledge ..",
    name: "arnav austin",
    role: "Web Development Course",
    image: test2,
    stars: 5,
  },
  {
    id: 12,
    Testimonail:
      "Nice Doctor, treatment is good. Less medicine's are advising. Consultation fee also very less. Great experience..",
    name: "Satyanarayana Murthy",
    role: "Data Analysis Course",
    image: test3,
    stars: 5,
  },
];

export const TestimonialsData4 = [
  {
    id: 1,
    Testimonail:
      "Hospital service is very good at reasonable cost. There hospitality towards patient  is too good. Hygenic treatment.",
    name: "Srinivas Rao Veeramusti",
    role: "Web Development Course",
    image: test1,
    stars: 5,
  },
  {
    id: 2,
    Testimonail: `Best hospital
      Hi qualified friendly doctor's
      Staff also really awesome people
      Tq Dr.vasantha mam`,
    name: "A uddin Shaik",
    role: "Web Development Course",
    image: test2,
    stars: 5,
  },
  {
    id: 3,
    Testimonail:
      "I underwent physical therapy for knee pain for seven days. I have received guidance for self therapy. The result is wonderful & great.",
    name: "Patri murthy",
    role: "Data Analysis Course",
    image: test3,
    stars: 5,
  },
  {
    id: 4,
    Testimonail:
      "FOR ABOUT TWO YEARS I HAD BEEN SUFFERING FROM KNEE PAIN. I HAVE TRIED STANDARD MEDICINE BUT IT HAD NOT GET ANY SATISFACTION BUT ONE DAY I HEARED ABOUT THIS HOSPITAL AND I WENT THERE . WHERE THE DOCTOR SUGGEST ME SOME GODD MEDICINE AFTER THAT I HAD BEGAIN TO TAKE THEM . TODAY I AM GLAD TO INFORM YOU THAT I AM ABSOLUTELY FINE. GOOD HOSPITABLITY AND HEALTH CARE. THANKS A LOT",
    name: "Aravind Dharmapuri",
    role: "Web Development Course",
    image: test1,
    stars: 5,
  },
  {
    id: 5,
    Testimonail:
      "Firstly i would like to thank Dr.Ramesh sir...My leg was broken (a small accident).His diagnosis and treatment was very good.One of the best orthopedics in the locality(dilshuknagar)",
    name: "sumalatha bhairi",
    role: "Web Development Course",
    image: test2,
    stars: 5,
  },
  {
    id: 6,
    Testimonail:
      "Gone late night with a lot of pain And get relieved in the very next day. Nice doctor ,dedicated staff and quality treatment.",
    name: "MALLI GARIKAPATI",
    role: "Data Analysis Course",
    image: test3,
    stars: 5,
  },
  {
    id: 7,
    Testimonail:
      "Great hospital and good doctors, treatment is very well In this hospital. We are happy 😊☺️",
    name: "sathish hrc",
    role: "Web Development Course",
    image: test1,
    stars: 5,
  },
  {
    id: 8,
    Testimonail:
      "Yesterday me and and my hubby meet Dr Ramesh sir he treated  to my husband  very good ..thankq Sir",
    name: "alekhya nakkala",
    role: "Web Development Course",
    image: test2,
    stars: 5,
  },
  {
    id: 9,
    Testimonail:
      "I slipped off and visited this hospital by Google reviews.Doctor Ramesh is patient enough to listen to all my problems and explained clearly about the injury happened.Recomended orthopedist!",
    name: "shravya palli",
    role: "Data Analysis Course",
    image: test3,
    stars: 5,
  },
  {
    id: 10,
    Testimonail: `Knee Replacement
      Hip Replacement
      Arthroscopic ACL repair
      Fracture and Spine fixation
      Tendon transfer & repair
      Shoulder Arthroscopy & Sports Injury
      Hand surgery`,
    name: "VANAPARTHY SWAMY",
    role: "Web Development Course",
    image: test1,
    stars: 5,
  },
  {
    id: 11,
    Testimonail:
      "The doctors are really good and will give you the best treatment without jumping into short term solutions.",
    name: "Lahari Puligedda",
    role: "Web Development Course",
    image: test2,
    stars: 5,
  },
  {
    id: 12,
    Testimonail:
      "Excellent treatment from the highly professional Doctors and provides treatment in a good manner with friendly environment...",
    name: "preethi varma",
    role: "Data Analysis Course",
    image: test3,
    stars: 5,
  },
];
