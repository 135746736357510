// DataList.js

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMediaQuery } from "react-responsive";

const SlickCarousel = ({ specialities }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  if (isMobile) {
    return (
      <div className="container">
        <div className="row">
          {specialities.map((speciality) => (
            <div key={speciality.id} className="service-card">
              <img src={speciality.image} alt={speciality.title} />
              <div>
                <h3>{speciality.title}</h3>
                <p>{speciality.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };

  return (
    <div className="container carousel-div">
      <Slider {...settings}>
        {specialities.map((speciality) => (
          <div key={speciality.id} className="slick-card">
            <img src={speciality.image} alt={speciality.title} />
            <div>
              <h3>{speciality.title}</h3>
              <p>{speciality.description}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SlickCarousel;
