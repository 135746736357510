import React from "react";
import { TestimonialsData } from "./TestimonialsData";
import bgImage from "./Media/testimonial-bg.webp";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
// import { Autoplay, Navigation, Pagination } from "swiper";
import "./Testimonials.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Aos from "aos";
import Fade from "react-reveal/Fade";

Aos.init({ duration: "1000" });

const Testimonials = () => {
  return (
    <Fade bottom>
      <div
        // style={{ backgroundImage: `url(${bgImage})` }}
        className=" -z-[100] Testimonials-part  ">
        <h1 className="main-title">Hear from our thriving community</h1>
        <p className="main-paragraph">
          Gain insights from our alumni about their experiences and successes
          after completing our courses.
        </p>
        <Fade bottom>
          <div className="testimonial-slide-div-outer">
            <Swiper
              mousewheel={true}
              loop={true}
              cssMode={true}
              centeredSlides={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              // modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper container testimonial-slide-div">
              {TestimonialsData.map((data, i) => (
                <SwiperSlide key={i}>
                  <div className="Testimonial-slide">
                    <div className="Testimonial-slide-left">
                      {/* <img className="test-img" src={data.image} alt="" /> */}
                      <p className="Testimonials-slide-heading ">{data.name}</p>
                      {/* <p className="Testimonials-content">{data.role}</p> */}
                    </div>
                    <div className="Testimonial-slide-right">
                      <p className="Testimonials-content">{data.Testimonail}</p>
                      {/* <hr className="Testimonial-hr" /> */}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Fade>
      </div>
    </Fade>
  );
};

export default Testimonials;
