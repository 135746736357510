import React, { useState } from "react";
import AwesomeSlider from "react-awesome-slider";
import { TestData } from "./TestData";
import "react-awesome-slider/dist/styles.css";
import "react-awesome-slider/dist/custom-animations/fall-animation.css";
import "react-awesome-slider/dist/custom-animations/cube-animation.css";
import "./Hero.css";
import withAutoplay from "react-awesome-slider/dist/autoplay";

function Hero() {
  const [desc, setDesc] = useState(TestData[0].desc);
  const AutoplaySlider = withAutoplay(AwesomeSlider);

  const handleSlideChange = ({ nextIndex, prevIndex }) => {
    const newIndex = typeof nextIndex !== "undefined" ? nextIndex : prevIndex;
    setDesc(TestData[newIndex].desc);
  };
  return (
    <div className="mt-[24px] md:mt-0">
      <AutoplaySlider
        // animation="fallAnimation"
        animation="cubeAnimation"
        onTransitionRequest={handleSlideChange}
        bullets={false}
        infinite={true}
        className="awesome-slider"
        play={true}
        interval={5000}>
        {TestData.map((product, index) => (
          <div key={product.id} className="slide">
            <img src={product.image} alt="" className="slide-image" />
            {/* <div className="hero-content">
              <h1 className="hero-title sm:text-6xl md:text-7xl lg:text-8xl xl:text-9xl">
                {product.title}
              </h1>
            </div> */}
          </div>
        ))}
      </AutoplaySlider>
    </div>
  );
}

export default Hero;
