import React, { useState } from "react";
import Swal from "sweetalert2";
import logo from "./../../Assets/Media/logo.png";
import { Link } from "react-router-dom";

import { FaFacebookF, FaYoutube, FaTwitter } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { BiRightArrow } from "react-icons/bi";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Thankyou from "./media/Thank you2.png";
import hand from "./media/righthand.gif";

import "./Footerr.css";
import { Button } from "../../Assets/Elements/Elements";

const Footerr = () => {
  const [input, setInput] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();

    if (input !== "") {
      Swal.fire({
        title: "Thanks for subscribing!",
        text: "You will be the first to know about our newsletter.",
        imageUrl: Thankyou,
        imageWidth: 400,
        imageHeight: 200,
        imageAlt: "welcome image",
      });
      setInput("");
    }
  };
  return (
    <>
      {" "}
      <div className=" footer-box">
        <div className="upper  items-center  border-b footer-part-one">
          <div className="">
            <h2 className="font-semibold text-2xl title mb-[20px] text-white ">
              We Care For You!
            </h2>
            <p className="text-[#323232a1] paragraph-white text-[16px] !text-white mt-[36px]">
              Schedule your appointment to get the best treatment from the very
              best doctors in Kothapet, Hyderabad.
            </p>
          </div>
          <div className="mt-[24px]">
            <Button>
              <a href="tel:+91 8686969640" className="anchor-main">
                Book An Appointment
              </a>
            </Button>
          </div>
        </div>
        <div className="row footer-part-two !m-0">
          <div className="col-md-4">
            <div>
              {/* <img src={logo} alt="" className="footer-logo" /> */}
              <h2 className="text-2xl font-bold mb-2 md:mt-[10px] text-white ">
                About Us
              </h2>
            </div>
            <p className="text-gray-500  !text-white paragraph-white mt-[20px] ">
              Vasavi Hospital is established and run by the very best and
              experienced doctors in Kothapet, Hyderabd. We serve in generel
              medicine and orthopaedics.
            </p>
          </div>
          <div class="col-md-3 md:ml-[5%]">
            <h2 className="text-2xl font-bold mb-2 md:mt-[10px] text-white ">
              Quick Links
            </h2>
            <ul className="text-white flex flex-col">
              <Link to="/" className="timeline-item-content">
                <li className="footer_links text-underline">About Us</li>
              </Link>
              <Link to="/" className="timeline-item-content">
                <li className="footer_links text-underline">Specialities</li>
              </Link>
              <Link to="/" className="timeline-item-content">
                <li className="footer_links text-underline">Services</li>
              </Link>
              <Link to="/" className="timeline-item-content">
                <li className="footer_links text-underline">Why Us</li>
              </Link>
            </ul>
          </div>
          <div className="col-md-4">
            <h2 className="text-2xl font-bold mb-2 md:mt-[10px] text-white ">
              Address
            </h2>
            <ul className="text-white text-[16px] text-[#ffffffdb] leading-[28px]">
              <li className="">
                H.No: 11-13-1161, Ram Pratap Nivas,
                <br /> Kothapet, Hyderabad, Telangana 500035
              </li>
              <li className="">
                <a href="tel: +918686969640" id="call">
                  <span className="inline-block">
                    <img src={hand} alt="" className="blog-hand1" />
                  </span>
                  <span className="footer-link123">+91 8686969640</span>
                </a>
                <ReactTooltip
                  anchorId="call"
                  place="left"
                  content="Book an appointment"
                  className="copy-box-home-vision"
                />
              </li>
              <li className="">
                <a href="mailto: info@terces.io" id="mail">
                  <span className="inline-block">
                    <img src={hand} alt="" className="blog-hand1" />
                  </span>
                  <span className="footer-link123">
                    vasavihospital.org@gmail.com{" "}
                  </span>
                </a>
                <ReactTooltip
                  anchorId="mail"
                  place="left"
                  content="Write to us"
                  className="copy-box-home-vision"
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-part-three">
          <div className="flex gap-[4px] terms-part-footer flex-row">
            Developed by
            <a
              href="https://www.linkedin.com/in/kranthi-kumar-mamidala/"
              className="footer-link123 inline"
              target="_blank">
              Kranthi Kumar M
            </a>
          </div>
          <div className="flex gap-[30px]">
            <a
              href="#"
              className="bg-white p-[6px] rounded-[15px] t-footer-icon"
              id="mission-div"
              rel="noopener noreferrer">
              <FaFacebookF className="text-[#002549] fb " />
            </a>
            <ReactTooltip
              anchorId="mission-div"
              place="top"
              content="Like Us"
              className="copy-box-home-vision"
            />
            <a
              href="#"
              className="bg-white p-[6px] rounded-[15px] t-footer-icon"
              id="mission-div1"
              rel="noopener noreferrer">
              <FaYoutube className="text-[#002549] yt" />
            </a>
            <ReactTooltip
              anchorId="mission-div1"
              place="top"
              content="Subscribe Us"
              className="copy-box-home-vision"
            />
            <a
              href="#"
              className="bg-white p-[6px] rounded-[15px]  t-footer-icon"
              id="mission-div2"
              rel="noopener noreferrer">
              <RiInstagramFill className="text-[#002549] insta" />
            </a>
            <ReactTooltip
              anchorId="mission-div2"
              place="top"
              content="Follow Us"
              className="copy-box-home-vision"
            />
            <a
              href="#"
              className="bg-white p-[6px] rounded-[15px] t-footer-icon"
              id="mission-div3"
              rel="noopener noreferrer">
              <FaTwitter className="text-[#002549] twitter" />
            </a>
            <ReactTooltip
              anchorId="mission-div3"
              place="top"
              content="Follow Us"
              className="copy-box-home-vision"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Footerr;
