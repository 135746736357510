import React from "react";
import {
  TestimonialsData,
  TestimonialsData2,
  TestimonialsData3,
  TestimonialsData4,
} from "./TestimonialsData";
import bgImage from "./Media/testimonial-bg.webp";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Autoplay, Navigation, Pagination } from "swiper";
import "./Testimonials.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Aos from "aos";
import Fade from "react-reveal/Fade";
import StarRatings from "react-star-ratings";
import { Heading, Title } from "../../Assets/Elements/Elements";

Aos.init({ duration: "1000" });

const NewTestimonials = () => {
  return (
    <Fade bottom>
      <div
        // style={{ backgroundImage: `url(${bgImage})` }}
        className=" -z-[100] Testimonials-part  ">
        <Title className="main-title">What they Say about us</Title>
        <Heading className="main-paragraph">
          Real stories, real experiences: Hear what our patients have to say
          about their recovery.
        </Heading>
        <div className="container container1">
          <div className="row">
            <div className="col-12 col-xl-3 col-lg-4 col-md-6 vertical-div">
              {TestimonialsData.map((data, i) => (
                <div key={i}>
                  <div className="Testimonial-slide-New">
                    <div className="Testimonial-slide-right-New">
                      <p className="Testimonials-content-New">
                        {data.Testimonail}
                      </p>
                      <StarRatings
                        rating={data.stars ? parseInt(data.stars, 10) : 0}
                        starRatedColor="gold"
                        numberOfStars={5}
                        name="rating"
                        starDimension="16px"
                        starSpacing="5px"
                        starHoverColor="#2153ac"
                      />
                      {/* <hr className="Testimonial-hr" /> */}
                    </div>
                    <div className="Testimonial-slide-left-New">
                      {/* <img className="test-img" src={data.image} alt="" /> */}
                      <div>
                        <p className="Testimonials-slide-heading-New ">
                          {data.name}
                        </p>
                        {/* <p className="Testimonials-content-New">{data.role}</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-12 col-xl-3 col-lg-4 col-md-6 vertical-div2">
              {TestimonialsData2.map((data, i) => (
                <div key={i}>
                  <div className="Testimonial-slide-New">
                    <div className="Testimonial-slide-right-New">
                      <p className="Testimonials-content-New">
                        {data.Testimonail}
                      </p>
                      <StarRatings
                        rating={data.stars ? parseInt(data.stars, 10) : 0}
                        starRatedColor="gold"
                        numberOfStars={5}
                        name="rating"
                        starDimension="16px"
                        starSpacing="5px"
                        starHoverColor="#2153ac"
                      />
                      {/* <hr className="Testimonial-hr" /> */}
                    </div>
                    <div className="Testimonial-slide-left-New">
                      {/* <img className="test-img" src={data.image} alt="" /> */}
                      <div>
                        <p className="Testimonials-slide-heading-New ">
                          {data.name}
                        </p>
                        {/* <p className="Testimonials-content-New">{data.role}</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-12 col-xl-3 col-lg-4 col-md-6 vertical-div3">
              {TestimonialsData3.map((data, i) => (
                <div key={i}>
                  <div className="Testimonial-slide-New">
                    <div className="Testimonial-slide-right-New">
                      <p className="Testimonials-content-New">
                        {data.Testimonail}
                      </p>
                      <StarRatings
                        rating={data.stars ? parseInt(data.stars, 10) : 0}
                        starRatedColor="gold"
                        numberOfStars={5}
                        name="rating"
                        starDimension="16px"
                        starSpacing="5px"
                        starHoverColor="#2153ac"
                      />
                      {/* <hr className="Testimonial-hr" /> */}
                    </div>
                    <div className="Testimonial-slide-left-New">
                      {/* <img className="test-img" src={data.image} alt="" /> */}
                      <div>
                        <p className="Testimonials-slide-heading-New ">
                          {data.name}
                        </p>
                        {/* <p className="Testimonials-content-New">{data.role}</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-12 col-xl-3 col-lg-4 col-md-6 vertical-div4">
              {TestimonialsData4.map((data, i) => (
                <div key={i}>
                  <div className="Testimonial-slide-New">
                    <div className="Testimonial-slide-right-New">
                      <p className="Testimonials-content-New">
                        {data.Testimonail}
                      </p>
                      <StarRatings
                        rating={data.stars ? parseInt(data.stars, 10) : 0}
                        starRatedColor="gold"
                        numberOfStars={5}
                        name="rating"
                        starDimension="16px"
                        starSpacing="5px"
                        starHoverColor="#2153ac"
                      />
                      {/* <hr className="Testimonial-hr" /> */}
                    </div>
                    <div className="Testimonial-slide-left-New">
                      {/* <img className="test-img" src={data.image} alt="" /> */}
                      <div>
                        <p className="Testimonials-slide-heading-New ">
                          {data.name}
                        </p>
                        {/* <p className="Testimonials-content-New">{data.role}</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default NewTestimonials;
