import React from "react";
import { Heading, Title } from "../../Assets/Elements/Elements";
import DataList from "../../Assets/Elements/DataList";
import { ServicesData } from "./ServicesData";
import SlickCarousel from "../../Assets/Elements/SlickCarousel";

function Services() {
  return (
    <div className="pattern-bg2">
      <Title>Our Services</Title>
      <Heading>
        Comprehensive Care, Always There: Your Path to Wellness, from
        Consultation to Recovery.
      </Heading>
      <SlickCarousel specialities={ServicesData} />
    </div>
  );
}

export default Services;
