import React from "react";
import { Heading, Title } from "../../Assets/Elements/Elements";
import { WhyChooseUsData } from "./WhyChooseUsData";
import DataDisplay from "../../Assets/Elements/DataDisplay";

function WhyChooseUs() {
  return (
    <div className="mt-[80px] pattern-bg">
      <Title>Why Choose Us</Title>
      <Heading>
        Comprehensive healthcare solutions tailored to you, always putting
        patients first
      </Heading>
      <DataDisplay specialities={WhyChooseUsData} />
    </div>
  );
}

export default WhyChooseUs;
