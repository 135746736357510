import OutPatient from "./../../Assets/Media/Services/1 Consultation.png";
import Admission from "./../../Assets/Media/Services/2 Admission.png";
import Emergency from "./../../Assets/Media/Services/3 Emergency.png";
import Laboratory from "./../../Assets/Media/Services/4 Laboratory.png";
import Radiography from "./../../Assets/Media/Services/5 Radiography.png";
import Blood from "./../../Assets/Media/Services/6 Blood bank.png";
import Pharmacist from "./../../Assets/Media/Services/7 Pharmacist.png";
import Phisiotherapy from "./../../Assets/Media/Services/8 Phisiotherapy.png";
import Vaccine from "./../../Assets/Media/Services/9 Vaccine.png";

export const ServicesData = [
  {
    id: 1,
    image: OutPatient,
    description:
      "Comprehensive medical consultation and treatment for various ailments on an outpatient basis.",
    title: "Out Patient Department",
  },
  {
    id: 2,
    image: Emergency,
    description:
      "Urgent medical care and consultation for emergency cases, available round the clock.",
    title: "Emergency Consultation",
  },
  {
    id: 3,
    image: Admission,
    description:
      "Admission and inpatient care for acute and chronic conditions, ensuring specialized treatment.",
    title: "Admission",
  },
  {
    id: 4,
    image: Vaccine,
    description:
      "Immunization services for children and adults, offering a range of vaccines for disease prevention.",
    title: "Vaccination",
  },
  {
    id: 5,
    image: Laboratory,
    description:
      "24/7 laboratory services for accurate diagnosis and monitoring of various health conditions.",
    title: "24/7 Laboratory",
  },
  {
    id: 6,
    image: Radiography,
    description:
      "Round-the-clock radiology services including X-rays, CT scans, and MRI scans for precise diagnosis.",
    title: "24/7 Radiology",
  },
  {
    id: 7,
    image: Blood,
    description:
      "Continuous availability of blood and blood products for transfusion, ensuring timely treatment.",
    title: "24/7 Blood Bank",
  },
  {
    id: 8,
    image: Pharmacist,
    description:
      "Fully stocked pharmacy offering a wide range of medications and healthcare products.",
    title: "Pharmacy",
  },
  {
    id: 9,
    image: Phisiotherapy,
    description:
      "Specialized rehabilitation and physiotherapy services to restore movement and function.",
    title: "Physiotherapy",
  },
];
