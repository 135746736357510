// elements.js

import React, { useEffect, useRef } from "react";
import "./Elements.css";
import { gsap } from "gsap";
import { FaArrowRight } from "react-icons/fa";

export const Button = ({ onClick, children }) => (
  <button className="main-btn-12" onClick={onClick}>
    {children}
  </button>
);

export const Title = ({ children }) => <h1 className="title">{children}</h1>;

export const Subtitle = ({ children }) => (
  <h2 className="subtitle">{children}</h2>
);

export const Heading = ({ children }) => (
  <h3 className="heading">{children}</h3>
);

export const Tagline = ({ children }) => <p className="tagline">{children}</p>;

export const Paragraph = ({ children }) => (
  <p className="paragraph">{children}</p>
);

export const Button2 = ({ children }) => {
  const linkRef = useRef(null);
  const pinkRef = useRef(null);

  useEffect(() => {
    const link = linkRef.current;
    const pink = pinkRef.current;

    const hoverTL = gsap.timeline({ paused: true });

    hoverTL.to(pink, {
      width: "calc(100% + 1.3em)",
      ease: "Elastic.easeOut(0.25)",
      duration: 0.4,
    });
    hoverTL.to(pink, {
      width: "2em",
      left: "calc(100% - 1.45em)",
      ease: "Elastic.easeOut(0.4)",
      duration: 0.6,
    });

    const handleMouseEnter = () => {
      hoverTL.play();
    };

    const handleMouseLeave = () => {
      hoverTL.reverse();
    };

    link.addEventListener("mouseenter", handleMouseEnter);
    link.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      link.removeEventListener("mouseenter", handleMouseEnter);
      link.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  return (
    <div className="wrapper">
      <a ref={linkRef} className="link" href="#">
        <div ref={pinkRef} className="color"></div>
        <span>{children}</span>
        <i className="fa-solid fa-arrow-right"></i>
      </a>
    </div>
  );
};
