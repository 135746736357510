import Affordable from "./../../Assets/Media/WhyChooseUs/1 Affordable.png";
import Quality from "./../../Assets/Media/WhyChooseUs/2 Quality.png";
import Patient from "./../../Assets/Media/WhyChooseUs/3 Patient.png";
import Services from "./../../Assets/Media/WhyChooseUs/4 Services.png";

export const WhyChooseUsData = [
  {
    id: 1,
    logo: Patient,
    description:
      "Our patients are our top priority, and we strive to provide compassionate and personalized care.",
    title: "Patient First",
  },
  {
    id: 2,
    logo: Quality,
    description:
      "We are committed to delivering high-quality healthcare services, ensuring the best outcomes for our patients.",
    title: "Quality Care",
  },
  {
    id: 3,
    logo: Affordable,
    description:
      "We believe that quality healthcare should be accessible to all, and we offer affordable treatment options.",
    title: "Affordable",
  },
  {
    id: 4,
    logo: Services,
    description:
      "We offer a wide range of comprehensive healthcare services under one roof.",
    title: "Comprehensive Services",
  },
];
