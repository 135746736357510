import React from "react";
import { SpecialitiesData } from "./SpecialitiesData";
import { Heading, Title } from "../../Assets/Elements/Elements";
import DataList from "../../Assets/Elements/DataList";

function Specialities() {
  return (
    <div className="pattern-bg">
      <Title>Our Specialities</Title>
      <Heading>
        Specialized Care, Personalized Solutions: Your Comprehensive Health
        Partner for Wellness and Vitality Across Every Speciality.
      </Heading>
      <DataList specialities={SpecialitiesData} />
    </div>
  );
}

export default Specialities;
