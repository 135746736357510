import General from "./../../Assets/Media/Specialities/1 General Medicine.jpeg";
import Diabetes from "./../../Assets/Media/Specialities/2 Diabetes.webp";
import astma from "./../../Assets/Media/Specialities/3 astma.jpeg";
import Thyroid from "./../../Assets/Media/Specialities/4 Thyroid.jpg";
import PCOD from "./../../Assets/Media/Specialities/5 PCOD-Problem.jpg";
import Orthopaedic from "./../../Assets/Media/Specialities/6 Orthopaedic.webp";
import JointReplacement from "./../../Assets/Media/Specialities/7 Joint-replacement.jpg";
import Pain from "./../../Assets/Media/Specialities/8 Pain Management.jpg";

export const SpecialitiesData = [
  {
    id: 1,
    image: General,
    description:
      "Comprehensive care for various medical conditions, from common cold to chronic diseases.",
    title: "General Medicine",
  },
  {
    id: 2,
    image: PCOD,
    description:
      "Expert diagnosis and management of Polycystic Ovary Syndrome (PCOS) for women's health.",
    title: "PCOD Specialist",
  },
  {
    id: 3,
    image: astma,
    description:
      "Specialized treatment plans to control and manage asthma symptoms effectively.",
    title: "Asthma Specialist",
  },
  {
    id: 4,
    image: Diabetes,
    description:
      "Tailored diet and lifestyle guidance for effective management of diabetes.",
    title: "Diabetician",
  },
  {
    id: 5,
    image: Thyroid,
    description:
      "Precise diagnosis and personalized treatment for thyroid disorders and imbalances.",
    title: "Thyroid Specialist",
  },
  {
    id: 6,
    image: Orthopaedic,
    description:
      "Expert care for bone, joint, and musculoskeletal conditions, ensuring mobility and comfort.",
    title: "Orthopaedic",
  },
  {
    id: 7,
    image: JointReplacement,
    description:
      "Advanced surgical techniques for joint replacements, restoring mobility and function.",
    title: "Joint Replacement",
  },
  {
    id: 8,
    image: Pain,
    description:
      "Holistic approach to alleviate chronic pain through medication, therapy, and interventions.",
    title: "Pain Management",
  },
];
