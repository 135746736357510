// SpecialitiesList.js

import React from "react";
import { Link } from "react-router-dom";
import { Button2 } from "./Elements";
import "./Elements.css";

const DataList = ({ specialities }) => {
  return (
    <div className="container">
      <div className="row">
        {specialities.map((speciality) => (
          <div
            key={speciality.id}
            className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
            <div className="speciality-card">
              <img src={speciality.image} alt={speciality.title} />
              <div className="special-content">
                <h3>{speciality.title}</h3>
                <p>{speciality.description}</p>
              </div>
              <Button2>Learn More ➜</Button2>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DataList;
