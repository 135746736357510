import React, { useRef, useState } from "react";
import Swal from "sweetalert2";
import "./Contact.css";
import Confetti from "react-confetti";
import { Heading, Title } from "../../Assets/Elements/Elements";
import { BiBorderRadius } from "react-icons/bi";

const Contact = () => {
  const [showConfetti, setShowConfetti] = useState(false);
  const formRef = useRef(null);

  const handleConfettiClick = () => {
    setShowConfetti(true);
    setTimeout(() => {
      setShowConfetti(false);
    }, 7000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await somePromise(); // Example of awaiting a promise
      // Rest of the code
      formRef.current.reset();
      Swal.fire(
        "Thank you for your message!",
        "We'll respond as soon as possible.",
        "success"
      );
    } catch (error) {
      // Error handling
    }
  };

  const somePromise = () => {
    return new Promise((resolve, reject) => {
      // Example promise that resolves after 1 second
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  };

  return (
    <div className="pattern-bg">
      {showConfetti && (
        <Confetti
          width={window.innerWidth * 0.95}
          height={window.innerHeight}
          className="m-auto"
          endAnimation="fadeOut"
        />
      )}{" "}
      <div>
        <Title>Contact Us</Title>
        <Heading>
          For any enquiries, please send us a message, we will get back to you.
        </Heading>
      </div>
      <section className="container mb-[40px]">
        <div className="row gap-[24px] md:gap-[0px]">
          <div className="col-12 col-md-6">
            <div className=" relative rounded-lg md:p-0 sm:p-12">
              <form onSubmit={handleSubmit} ref={formRef}>
                <div className="mb-[20px]">
                  <input
                    required
                    type="text"
                    placeholder="Your Name"
                    className="contact-input w-[49%] mr-[1%] contact-paragraph"
                  />
                  <input
                    required
                    type="email"
                    placeholder="Your Email"
                    className="contact-input  w-[49%] ml-[1%] contact-paragraph"
                  />
                </div>

                <div className="mb-[20px]">
                  <input
                    required
                    type="text"
                    placeholder="Your Phone"
                    className="contact-input w-full contact-paragraph"
                  />
                </div>
                <div className="mb-[20px]">
                  <textarea
                    required
                    rows="6"
                    placeholder="Your Message"
                    className="contact-input w-full contact-paragraph"></textarea>
                </div>
                <div>
                  <button type="submit" className="send-msg-btn">
                    Send Message
                  </button>
                </div>
              </form>
              <div>
                <span className="absolute -top-10 -right-9 z-[-1]"></span>
                <span className="absolute -right-10 top-[90px] z-[-1]"></span>
                <span className="absolute -left-7 -bottom-7 z-[-1]"></span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className=" mb-12 lg:mb-0 w-full h-full">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5218.329571898898!2d78.54675267301295!3d17.362571363391556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb98c0472a7ba1%3A0x1d29af1715282c4c!2sVasavi%20Hospital%3A%20Orthopedic%2C%20Physician%2C%20Diabetes%2C%20Thyroid%2C%20Sugar%20Doctor%20%7C%20Hip%2FKnee%20Replacement%2C%20Back%20Pain%20%7C%20Hospital!5e0!3m2!1sen!2sin!4v1713864863393!5m2!1sen!2sin"
                width="100%"
                height="100%"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                style={{ borderRadius: "20px" }}></iframe>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
