import React, { useEffect, useState } from "react";
import Header from "../Components/Header/Header";
import Hero from "../Components/Hero/Hero";
import Specialities from "../Components/Specialities/Specialities";
import Services from "../Components/Services/Services";
import Testimonials from "../Components/Testimonials/Testimonials";
import NewTestimonials from "../Components/Testimonials/NewTestimonials";
import Footerr from "../Components/Footerr/Footerr";
import WhyChooseUs from "../Components/WhyChooseUs/WhyChooseUs";
import AboutUsCard1 from "../Components/AboutUs/AboutUsCard1";
import ourstory from "./../Assets/Media/Vasavi 2.png";
import Contact from "../Components/Contact/Contact";
import logo from "./../Assets/Media/logo.png";

function HomePage() {
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const sections = [
        { id: "about", offset: document.getElementById("about").offsetTop },
        {
          id: "specialities",
          offset: document.getElementById("specialities").offsetTop,
        },
        {
          id: "services",
          offset: document.getElementById("services").offsetTop,
        },
        { id: "contact", offset: document.getElementById("contact").offsetTop },
      ];

      const currentSection = sections.find(
        (section) =>
          scrollPosition >= section.offset &&
          scrollPosition < section.offset + window.innerHeight
      );

      if (currentSection) {
        setActiveSection(currentSection.id);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      {/* <Header /> */}
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img src={logo} alt="" className="header-logo" />
          </a>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse navbar-items"
            id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto">
              <li
                className={`nav-item ${
                  activeSection === "about" ? "active" : ""
                }`}>
                <a className="nav-link" href="#about">
                  About Us
                </a>
              </li>
              <li
                className={`nav-item ${
                  activeSection === "specialities" ? "active" : ""
                }`}>
                <a className="nav-link" href="#specialities">
                  Specialities
                </a>
              </li>
              <li
                className={`nav-item ${
                  activeSection === "services" ? "active" : ""
                }`}>
                <a className="nav-link" href="#services">
                  Services
                </a>
              </li>
              <li
                className={`nav-item ${
                  activeSection === "contact" ? "active" : ""
                }`}>
                <a className="nav-link" href="#contact">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>

          <div className="d-none d-lg-block">
            <a href="tel: +91 8686969640" className="btn btn-outline-success">
              Book Appointment
            </a>
          </div>
        </div>
      </nav>
      <Hero />
      <div id="about">
        <AboutUsCard1
          title="About Us"
          description1="
        Vasavi Hospital, situated conveniently in the heart of Hyderabad, stands as a beacon of healthcare excellence since its inception in 2015. Founded by Dr. Vasantha Jella, an esteemed physician specializing in Diabetology, Thyroid disorders, and Fever management, alongside Dr. Ramesh, a skilled and dynamic surgeon. "
          description2="
        The hospital embodies a commitment to providing comprehensive medical care. Located in proximity to areas like Nagole, Lb Nagar, Bandlaguda, Alkapuri, and Jaipur colony, the hospital ensures easy access for patients across Hyderabad."
          image={ourstory}
        />
      </div>
      <div id="specialities">
        <Specialities />
      </div>
      <div id="services">
        <Services />
      </div>
      <WhyChooseUs />
      <NewTestimonials />
      <div id="contact">
        <Contact />
      </div>
      <Footerr />
      <a
        href="tel: +91 8686969640"
        className="main-btn-12 moblie-btn md:hidden">
        Book Appointment
      </a>
    </div>
  );
}

export default HomePage;
