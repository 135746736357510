import img1 from "./../../Assets/Media/Hero/RN3.png";
import img2 from "./../../Assets/Media/Hero/VN3.png";

export const TestData = [
  {
    id: 1,
    image: img1,
    title: "Welcome to Vasavi Hospital",
    desc: "We care for your health",
  },
  {
    id: 2,
    image: img2,
    title: "Diabetes Management",
    desc: "",
  },
];
