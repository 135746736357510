// WhyChooseUsDisplay.js

import React from "react";
import { WhyChooseUsData } from "../../Components/WhyChooseUs/WhyChooseUsData";

const DataDisplay = () => {
  return (
    <div className="container mb-[24px]">
      <div className="row gap-[24px] md:gap-[0px]">
        {WhyChooseUsData.map((point) => (
          <div key={point.id} className="col-12 col-md-6 col-lg-3">
            <div className="why-choose-card">
              <img
                src={point.logo}
                alt={point.title}
                className="me-3"
                style={{ maxWidth: "60px" }}
              />

              <h4>{point.title}</h4>
              <p>{point.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DataDisplay;
