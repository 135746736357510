import React from "react";
import "./AboutUsCards.css";

function AboutUsCard1({
  image,
  title,
  heading,
  description1,
  description2,
  button,
  link,
}) {
  return (
    <div className="container mt-[40px] pattern-bg2">
      <div className="row">
        <div className="col-12 col-md-6 flex flex-col justify-center">
          <h1 className="text-[#007eeb] font-[700] text-[36px]">{title}</h1>
          <br />
          <p className="about-us-paragraph">{description1}</p>
          <br />
          <p className="about-us-paragraph">{description2}</p>
        </div>
        <div className="col-12 col-md-6 flex flex-col justify-center mt-[24px] md:mt-[0px]">
          <img
            src={image}
            alt="service"
            className="gold-product-component-image"
          />
        </div>
      </div>
    </div>
  );
}

export default AboutUsCard1;
